import { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from './components/header/Header';
import Form from './components/form/Form';
import Home from './components/home/Home';
import Footer from './components/footer/Footer';
import SideMenu from './components/sideMenu/SideMenu';
import Atlas from './components/atlas/Atlas';
import Jkp from './components/Jkp/Jkp';

function App() {
  const [expand, setExpand] = useState(false);
  return (
    <div id="root" className="App">
      <Router>
        <sdds-theme />
        <div className={`sdds-navbar-overlay ${expand ? 'expanded' : ''}`} />
        <Header onDrawerClick={() => setExpand(!expand)} expand={expand} />
        <div className="sdds-push sdds-demo-container">
          <SideMenu expand={expand} onCloseClick={() => setExpand(!expand)}/>
          <div className={'sdds-content-push'}>
            <div className="sdds-container-fluid content-wrapper">
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path="/form">
                  <Form />
                </Route>
                <Route path="/atlas">
                  <Atlas />
                </Route>
                <Route path="/jkp">
                  <Jkp />
                </Route>
              </Switch>
            </div>
            <Footer />
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;
