import { useRef } from 'react';

const Home = () => {
  const myModal = useRef(null);

  const openModalManual = () => {
    const currentClass = myModal.current.classList;

    if (currentClass.contains('hide')) {
      myModal.current.classList.remove('hide');
      myModal.current.classList.add('show');
    } else {
      myModal.current.classList.remove('show');
      myModal.current.classList.add('hide');
    }
  };

  return (
    <div className="sdds-row">
      <div className="sdds-col-xlg-16 sdds-col-md-8 sdds-col-sm-4">
        <p className="lead-head sdds-paragraph-01">
        This is an example of showing C-ITS for the 2 current l4 projects
        </p>
      </div>
    </div>
  );
};

export default Home;
