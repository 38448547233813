import React, { useState } from 'react';
import IconButton from '../IconButton/IconButton';
import { NavLink } from 'react-router-dom';

const SideMenuItem = ({ itemName, link, collapse, activeFcn, active}) => {
  const [showPopOver, setShowPopOver] = useState(false);
  const [posX, setPosX] = useState(null);
  const [posY, setPosY] = useState(0);

  function onHoverMenu(event) {
    setShowPopOver(!showPopOver);
    const elem = event.target;
    setPosX(elem.getBoundingClientRect().left + elem.offsetWidth + 2);
    setPosY(elem.getBoundingClientRect().top);
  }

  return (

     <li class={`sdds-sidebar-nav__item ${active? 'sdds-item--active':''}`}>
      <NavLink
        className={`nav-link sdds-sidebar-nav__item`}
        to={link}
        style={{ color: 'black' }}
        onMouseEnter={(e) => onHoverMenu(e)}
        onMouseLeave={() => setShowPopOver(!showPopOver)}
        onClick={()=> {activeFcn(itemName)}}
      >

        <a className="sdds-sidebar-nav__item-link" href={link}>
        <IconButton />
        <span class="sdds-sidebar-nav__item-text">{itemName}</span>

        </a>
      </NavLink>
      </li>

  );
};

export default SideMenuItem;
