import './Footer.css';

const Footer = () => {
  return (
    <div className="sdds-footer">
      <div className="sdds-footer-main">
        <div className="sdds-footer-main-brand">
          <p>Copyright &copy; 2022 Scania</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
