import React, {  useEffect, useState} from "react";

export const Atlas = () => {

    return(
        <>
        <div>This is a test hmi for Atlas</div>
        </>
    )
};
export default Atlas;